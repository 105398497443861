.App {
    background-color: #FFFAFA;
  }
  
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  /* width: 100vw; */
  /* height: 100vh; */
}

.Auth-form {
  background: #1C1E1F;
  width: 420px;
  box-shadow: rgb(255, 253, 253) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: #FFFAFA;
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: #FFFAFA;
}

body {
  background: #1C1E1F;
}

input[type="text"], input[type="password"], textarea {
  background-color : #FFFAFA; 
}

input {
  margin-top: 0.5rem;
}